import React from "react";
import { Paper } from "@material-ui/core";
export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Our values are aligned to our admbitions and geared to challenging
            our clients
          </p>
        </div>
        <div className="row">
          <Paper style={{ backgroundColor: "grey 5%" }}>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      {/* <div className="list-style"> */}
                      <ul>
                        {d.text.split(",").map((_text) => (
                          <li key={_text}>
                            <p>{_text}</p>
                          </li>
                        ))}
                      </ul>
                      {/* </div> */}
                    </div>
                  </div>
                ))
              : "loading"}
          </Paper>
        </div>
      </div>
    </div>
  );
};
